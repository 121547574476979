import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const DATE_FORMAT = 'MMMM DD, YYYY';
const TIME_FORMAT = 'h:mm A';
const TZ_FORMAT = 'z';

const formatDate = (date: Date, tz?: string, format?: string) => {
  return dayjs(date)
    .tz(tz || 'America/New_York')
    .format(format || 'MMMM DD, YYYY h:mm A z');
};

export const formatBrandEventDate = (startDate: Date, endDate?: Date, tz?: string) => {
  const startDateText = formatDate(startDate, tz, DATE_FORMAT);
  const endDateText = endDate ? formatDate(endDate, tz, DATE_FORMAT) : null;
  const startTimeText = formatDate(startDate, tz, TIME_FORMAT);
  const endTimeText = endDate ? formatDate(endDate, tz, TIME_FORMAT) : null;
  const tzText = formatDate(startDate || endDate, tz, TZ_FORMAT);

  const isSameDay = startDateText === endDateText;

  if (isSameDay) {
    return `${startDateText} ${
      !endDateText ? `@ ${startTimeText} ${tzText}` : `@ ${startTimeText} - ${endTimeText} ${tzText}`
    }`;
  } else {
    return `${startDateText} @ ${startTimeText} ${
      !endDateText ? `${tzText}` : `- ${endDateText} @ ${endTimeText} ${tzText}`
    }`;
  }
};
