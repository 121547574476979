import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';

import { ICollectible, RewardTypes } from '../../types';
import CollectibleMedia from '../CollectibleMedia';

export interface EventCollectibleRewardProps {
  type: RewardTypes;
  collectible?: Partial<ICollectible>;
  quantity?: number;
}

const EventCollectibleReward: React.FC<EventCollectibleRewardProps> = ({ type, collectible, quantity }) => {
  return (
    <Box>
      {type === 'COLLECTIBLE' && (
        <Flex flexDirection={'row'} alignItems={'center'}>
          <Box>
            <CollectibleMedia width={32} height={32} metadata={collectible?.metadata} borderRadius="8px" />
          </Box>
          <Text variant="text6-400" ml={'8px'}>
            Earn <span style={{ fontWeight: 'bold' }}>{collectible?.metadata?.name || ''}</span> when you attend this
            event
          </Text>
        </Flex>
      )}
      {type === 'BRAND_COIN' && (
        <Flex flexDirection={'row'} alignItems={'center'}>
          <Box>
            <CollectibleMedia width={32} height={32} metadata={collectible?.metadata} borderRadius="8px" />
          </Box>
          <Text variant="text6-400" ml={'8px'}>
            Earn{' '}
            <span style={{ fontWeight: 'bold' }}>
              {quantity} {collectible?.metadata?.name || ''} {quantity > 1 ? 'coins' : 'coin'}
            </span>{' '}
            when you attend this event
          </Text>
        </Flex>
      )}
      {type === 'REPS_ONLY' && (
        <Flex>
          <Text variant="text6-400">
            Earn <span style={{ fontWeight: 'bold' }}>+1 Rep</span> when you attend this event
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default EventCollectibleReward;
