import { BrandEventTabs } from 'src/constants';

import Tab from '../../Tab';

const BrandEventsTabs: React.FC<{
  selectedTab: BrandEventTabs;
  onChangeTab: (tab: BrandEventTabs) => void;
}> = ({ selectedTab, onChangeTab }) => (
  <Tab selectedTab={selectedTab} onClick={onChangeTab}>
    <Tab.Item tab={BrandEventTabs.Upcoming} fontSize="16px">
      {BrandEventTabs.Upcoming}
    </Tab.Item>
    <Tab.Item tab={BrandEventTabs.Past} fontSize="16px">
      {BrandEventTabs.Past}
    </Tab.Item>
  </Tab>
);

export default BrandEventsTabs;
