import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useState } from 'react';
import { IBrandWallet } from 'src/interface/IBrandWallet';

import CollectibleViewModal from '../../../modals/CollectibleViewModal/CollectibleViewModal';
import { ICollectible } from '../../../types';
import { getCollectibleMetadata } from '../../../utils/getCollectibleMetadata';
import CollectibleMedia from '../../CollectibleMedia';

export interface EventCardProps {
  gateCollectibles?: ICollectible[];
  brandWallet?: Partial<IBrandWallet>;
  hasBorder?: boolean;
}

const EventGateCollectibles: React.FC<EventCardProps> = ({ gateCollectibles, brandWallet, hasBorder }) => {
  const [isCollectibleModalOpen, setIsCollectibleModalOpen] = useState<boolean>(false);
  const [collectibleData, setCollectibleData] = useState<ICollectible>();

  return (
    gateCollectibles?.length > 0 && (
      <Box p={hasBorder && '16px'} borderRadius={hasBorder && '8px'} border={hasBorder && 'thin'}>
        <Text pb="10px" variant="text6-400">
          Must own one of the following collectibles to attend:
        </Text>

        <Flex flexDirection="column">
          {gateCollectibles.map((collectible, i) => (
            <Flex
              key={i}
              pt="8px"
              alignItems="center"
              __css={{
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => {
                setCollectibleData({
                  ...collectible,
                  metadata: {
                    ...collectible.metadata,
                    ...getCollectibleMetadata({
                      metadata: collectible.metadata,
                      collectibleExtraMetadatas: brandWallet?.collections.flatMap((cs) => cs.collectibleExtraMetadatas),
                      contractAddress: collectible.contractAddress,
                      type: collectible.type,
                      tokenId: collectible.tokenId,
                    }),
                  },
                });
                setIsCollectibleModalOpen(true);
              }}
            >
              <CollectibleMedia width={36} height={36} metadata={collectible?.metadata} borderRadius="8px" />
              <Text variant="text6-400" ml="8px">
                {collectible?.metadata.name}
              </Text>
            </Flex>
          ))}
        </Flex>

        <CollectibleViewModal
          isOpen={isCollectibleModalOpen}
          onClose={setIsCollectibleModalOpen}
          collectible={collectibleData}
          showTokenId={false}
          isOnBrandPage={true}
          isCollectibleVisible={false}
        />
      </Box>
    )
  );
};

export default EventGateCollectibles;
