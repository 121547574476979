import { gql } from '@apollo/client';
import { BrandEventType } from 'src/interface/IBrandEvent';

import { ICollection, RewardTypes } from '../../types';

export interface BrandEventData {
  type: BrandEventType;
  link?: string;
  address?: string;
  googleMapsPlaceResult?: string;
  coverImageUrl: string;
  entitySlug: {
    slug: string;
    type: string;
    altId: string;
  };
  name: string;
  status: 'PUBLIC' | 'DRAFT';
  capacity?: number;
  date: Date;
  endDate: Date;
  publishedAt?: Date;
  tz: string;
  participationCount: number;
  rsvpParticipationCount: number;
  description: string;
  id: number;
  uuid: string;
  gates?: {
    contractAddress: string;
    quantity: number;
    tokenId: string;
  }[];
  reward?: {
    assetId?: string;
    contractAddress: string;
    quantity: number;
    reps: number;
    type: RewardTypes;
  };
  brand?: {
    uuid?: string;
    name: string;
    avatarUrl?: string;
    urlSlug?: string;
    logoUrl?: string;
    brandWallet?: {
      address?: string;
      collections?: ICollection[];
    };
  };
  lastParticipations: Array<{
    user: {
      avatarUrl: string;
      id: number;
    };
  }>;
}

export interface ActiveBrandEventsData {
  activeBrandEvents: BrandEventData[];
}

export interface ActiveBrandEventsVars {
  brandUuid: string;
}

export interface InvitedBrandEventsData {
  invitedBrandEvents: BrandEventData[];
}

export const ACTIVE_BRAND_EVENTS = gql`
  query ActiveBrandEvents($brandUuid: String!, $sort: String!) {
    activeBrandEvents(brandUuid: $brandUuid, sort: $sort) {
      address
      link
      coverImageUrl
      name
      participationCount
      googleMapsPlaceResult
      lastParticipations {
        user {
          avatarUrl
          id
        }
      }
      reward {
        assetId
        contractAddress
        quantity
        reps
        requiresApproval
        type
        uuid
      }
      capacity
      createdAt
      date
      endDate
      tz
      description
      entitySlug {
        slug
        type
        altId
      }
      gates {
        contractAddress
        id
        tokenId
        quantity
        sourceType
        updatedAt
        uuid
      }
      id
      updatedAt
      uuid
    }
  }
`;

export const INVITED_BRAND_EVENTS = gql`
  query InvitedBrandEvents($sort: String!) {
    invitedBrandEvents(sort: $sort) {
      address
      coverImageUrl
      name
      googleMapsPlaceResult
      participationCount
      lastParticipations {
        user {
          avatarUrl
          id
        }
      }
      brand {
        uuid
        brandWallet {
          address
          collections {
            contractAddress
            type
            baseCollectibleMetadata {
              name
              image
              description
              animationUrl
            }
          }
        }
      }
      reward {
        assetId
        contractAddress
        quantity
        reps
        requiresApproval
        type
        uuid
      }
      capacity
      createdAt
      date
      endDate
      tz
      description
      entitySlug {
        slug
        type
        altId
      }
      gates {
        contractAddress
        id
        tokenId
        quantity
        sourceType
        updatedAt
        uuid
      }
      id
      updatedAt
      uuid
    }
  }
`;
