import { useLazyQuery } from '@apollo/client';
import { Divider } from '@mantine/core';
import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import router from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { IBrandWallet } from 'src/interface/IBrandWallet';

import {
  BRAND_EVENT_PARTICIPATIONS,
  IBrandEventParticipationData,
  IBrandEventParticipationVars,
} from '../../graphql/queries/brandEventParticipations';
import { BrandEventData } from '../../graphql/queries/brandEvents';
import UserInfiniteListModal from '../../modals/UserInfiniteListModal/UserInfiniteListModal';
import { IBrandReward, ICollectible } from '../../types';
import { formatBrandEventDate } from '../../utils/formatBrandEventDate';
import { AvatarRow } from '../AvatarRow/AvatarRow';
import ImageCDN from '../ImageCDN';
import EventCollectibleReward from './EventCollectibleReward';
import EventGateCollectibles from './EventGateCollectibles/EventGateCollectibles';

export interface EventCardProps {
  event: Partial<BrandEventData>;
  onlyPreview?: boolean;
  reward?: IBrandReward;
  gateCollectibles?: ICollectible[];
  brandWallet: IBrandWallet;
  width?: string;
}

const BrandEventCard: React.FC<EventCardProps> = ({
  event,
  onlyPreview,
  reward,
  gateCollectibles,
  brandWallet,
  width = '330px',
}) => {
  const [listOpen, setListOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>(null);

  const [participationRefetch, { loading: participationLoading, error: participationsError, data: participatioData }] =
    useLazyQuery<IBrandEventParticipationData, IBrandEventParticipationVars>(BRAND_EVENT_PARTICIPATIONS, {
      variables: {
        brandEventUuid: event.uuid,
        input: {
          skip: 0,
          take: 20,
        },
      },
    });

  const loadMore = async (take: number, skip: number) => {
    participationRefetch({
      variables: {
        brandEventUuid: event.uuid,
        input: {
          skip,
          take,
        },
      },
    });
  };

  useEffect(() => {
    if (listOpen) {
      loadMore(20, 0);
    }
  }, [listOpen]);

  const onRedirectToEvent = useCallback(
    () => !onlyPreview && router.push(`/events/${event?.entitySlug?.altId}`),
    [onlyPreview, event?.entitySlug?.altId]
  );

  return (
    <Box
      __css={{ cursor: onlyPreview ? 'auto' : 'pointer' }}
      width={width}
      border="thin"
      borderRadius="md"
      height={'fit-content'}
      elevation="none"
      minWidth="330px"
      data-testid="brand-event-card"
    >
      {listOpen && (
        <UserInfiniteListModal
          title="INVITATIONS"
          isOpen={listOpen}
          users={participationLoading ? [] : participatioData?.brandEventParticipations.map((item) => item.user)}
          loadMore={loadMore}
          onViewWallet={(userId) => setSelectedUserId(String(userId))}
          total={event?.participationCount || 0}
          onClose={() => {
            setSelectedUserId(null);
            loadMore(20, 0);
            setListOpen(false);
          }}
        />
      )}

      {event.coverImageUrl && (
        <Box onClick={onRedirectToEvent}>
          <ImageCDN
            src={event.coverImageUrl || ''}
            width="100%"
            style={{
              objectFit: 'cover',
              borderTopLeftRadius: '0.5rem',
              borderTopRightRadius: '0.5rem',
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
            }}
            alt={event.name}
            height="330px"
          />
        </Box>
      )}
      <Flex flexDirection={'column'} p="15px" __css={{ gap: '15px' }}>
        <Flex flexDirection="column" __css={{ gap: '15px' }} onClick={onRedirectToEvent}>
          <Text variant="text4-600">{event?.name || 'Event name...'}</Text>
        </Flex>
        <Flex flexDirection="column" __css={{ gap: '15px' }} onClick={onRedirectToEvent}>
          <Text variant="text5-400">{formatBrandEventDate(event.date, event.endDate, event.tz)}</Text>
        </Flex>
        {!participationsError && event?.participationCount > 0 && (
          <Flex>
            <AvatarRow
              itemWidth={36}
              list={event?.lastParticipations.map((item) => item.user)}
              total={event?.participationCount}
              max={3}
              showList={() => setListOpen(true)}
              showUserId={listOpen ? selectedUserId : null}
            />
          </Flex>
        )}

        <EventCollectibleReward type={reward?.type} collectible={reward?.collectible} quantity={reward?.quantity} />

        <Button flex={1} variant="secondary" onClick={onRedirectToEvent}>
          View event
        </Button>
        {gateCollectibles?.length > 0 && (
          <>
            <Divider color="gray" mx="-5px" />
            <EventGateCollectibles gateCollectibles={gateCollectibles} brandWallet={brandWallet} />
          </>
        )}
      </Flex>
    </Box>
  );
};

export default BrandEventCard;
