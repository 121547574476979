import { gql } from '@apollo/client';

import { ParticipationForBrandEventStatus } from '../../types';

export interface IBrandEventParticipationVars {
  brandEventUuid: string;
  input?: {
    status?: Array<ParticipationForBrandEventStatus>;
    skip?: number;
    take?: number;
  };
}

export interface IBrandEventParticipationData {
  brandEventParticipations: {
    uuid: string;
    status: ParticipationForBrandEventStatus;
    isBrandMember: boolean;
    user: {
      id: number;
      uuid: string;
      urlSlug: string;
      avatarUrl: string;
      userName: string;
      email: string;
      cChainPublicAddress: string;
    };
  }[];
}

export const BRAND_EVENT_PARTICIPATIONS = gql`
  query BrandEventParticipations($brandEventUuid: String!, $input: BrandEventPaginatedFiltersInput) {
    brandEventParticipations(brandEventUuid: $brandEventUuid, input: $input) {
      status
      uuid
      isBrandMember
      user {
        id
        uuid
        cChainPublicAddress
        avatarUrl
        userName
        email
        urlSlug
      }
    }
  }
`;
